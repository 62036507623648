import React from 'react';

class Footer extends React.Component {
  render() {
    return (

      <footer>
        <a href="#">
          <h4 className="footer-name">Budget-Pal</h4>
        </a>
      </footer>

    );
  }
}

export default Footer;
